import {HOUR_IN_SECONDS, MINUTE_IN_SECONDS} from "../../utils/constants";

export default function (dateString) {
  let date = new Date(Date.parse(dateString))

  return (
    date.getHours() * HOUR_IN_SECONDS +
    date.getMinutes() * MINUTE_IN_SECONDS +
    date.getSeconds()
  )
}
