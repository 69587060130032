/**
 * Lines must be delimited by CRLF
 */
const END = '\r\n'

/**
 * Generates the ICS content
 * @param {array} events The events that will be added to the calendar
 * @return {string}
 */
export default function generateICS (events) {
  let content = `BEGIN:VCALENDAR${END}`
  content += `VERSION:2.0${END}`
  content += `PRODID:-//Ciena//NONSGML Ciena Tech Forum//EN${END}`

  for (let i = 0; i < events.length; i++) {
    content += singleEventEntry(events[i])
  }

  content += 'END:VCALENDAR'

  return content
}

/**
 * Generates the content for a single entry for the calendar
 * @param {object} event
 * @return {string}
 */
function singleEventEntry (event) {
  let description = event.shortDescription.replace(/(<\/?.*?>)/gm, '')

  let content = `BEGIN:VEVENT${END}`
  content += `UID:${event.UUID}${END}`
  content += `DTSTAMP:${dateToTimestamp(event.start)}${END}`
  content += `DTSTART:${dateToTimestamp(event.start)}${END}`
  content += `DTEND:${dateToTimestamp(event.end)}${END}`
  content += limitLength(`SUMMARY:${event.name}`) + END
  content += limitLength(`DESCRIPTION:${description}`) + END
  if (event.img) {
    content += `ATTACH:${event.img}${END}`
  }
  content += `LOCATION:Virtual Event Lobby${END}`
  content += `END:VEVENT${END}`

  return content
}

/**
 * Limit the input string to the provided length and split after that limit. The strings are joined back together
 * using the delimiter and a whitespace
 * @see https://icalendar.org/iCalendar-RFC-5545/3-1-content-lines.html
 * @param {string} str
 * @param {number} limit Default limit is 70 characters
 * @return {string}
 */
function limitLength (str, limit = 70) {
  let pieces = []

  for (let i = 0; i < str.length; i += limit) {
    pieces.push(str.substr(i, limit).trim())
  }

  return pieces.join(END + ' ')
}

/**
 * Format the date in the accepted format for the calendar
 * @param {string} dateString
 * @return {string}
 */
function dateToTimestamp (dateString) {
  let timestamp = dateString
    .replace(/[-:]/g, '')
    .replace(' ', 'T')
    .toUpperCase()

  return timestamp[timestamp.length - 1] === 'Z' ? timestamp : timestamp + 'Z'
}
