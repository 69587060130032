/**
 * Shows the current user's UTC offset
 */
export default function showTimezoneOffset() {
  let date = new Date()

  let offsetString = ''
  if (date.getTimezoneOffset() !== 0) {
    let offset = (date.getTimezoneOffset() / 60) * -1
    offsetString = offset < 0 ? offset : `+${offset}`
  }

  document.querySelector('.calendar-wrapper .header .timezone').innerHTML = `Your local time zone is UTC${offsetString}`
}
