import generateHeaderForColumn from "./generateHeaderForColumn";
import {GRID_HEIGHT_SIZE, GRID_HEIGHT_UNIT} from "../../utils/constants";
import generateSessionBox from "./generateSessionBox";

export default function (group, maxDiff) {
  const column = document.createElement('div')
  column.classList.add('column')
  column.dataset.date = group.date

  // header
  column.appendChild(generateHeaderForColumn(group.day, group.date))

  // session boxes
  let sessionsWrapper = document.createElement('div')
  sessionsWrapper.classList.add('column-sessions')
  sessionsWrapper.style.height = window.COLUMN_HEIGHT
  sessionsWrapper.style.backgroundImage = 'linear-gradient(rgba(255, 255, 255, .1) 1px, transparent 1px)'
  sessionsWrapper.style.backgroundSize = `100% ${GRID_HEIGHT_SIZE}${GRID_HEIGHT_UNIT}`
  for (let i = 0; i < group.sessions.length; i++) {
    sessionsWrapper.appendChild(generateSessionBox(group.sessions[i]))
  }

  column.appendChild(sessionsWrapper)

  return column
}
