export default function registerTrackingEvents() {
  // video ended events (play events are treated separetly)
  document.querySelectorAll("video").forEach((video) => {
    video.addEventListener("ended", () => {
      window.gtag("event", "video_ended", {});
    });
  });

  // register the click on links
  document.querySelectorAll("a[data-gtag]").forEach((link) => {
    link.addEventListener("click", (e) => {
      let [action, category, label] = link.dataset.gtag.split("|");
      window.gtag("event", action, {
        event_category: category || null,
        event_label: label || null,
      });
    });
  });
}
