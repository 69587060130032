const ADDED_TO_CALENDAR_CLASS = 'added-to-calendar'

export default function toggleTopicForDownload(topic) {
  if (!window.DOWNLOAD_TOPICS) {
    window.DOWNLOAD_TOPICS = []
  }

  const index = window.DOWNLOAD_TOPICS.indexOf(topic.UUID)
  if (index < 0) {
    window.DOWNLOAD_TOPICS.push(topic.UUID)
    window.gtag('event', 'click', {
      'event_category': 'add_to_calendar',
      'event_label': topic.name
    })
  } else {
    window.DOWNLOAD_TOPICS.splice(index, 1)
    window.gtag('event', 'click', {
      'event_category': 'remove_from_calendar',
      'event_label': topic.name
    })
  }
  updateSessionActiveClass()
  updateTopicActiveClass()
}

function updateSessionActiveClass() {
  const activeSessions = []

  for (let i = 0; i < window.allSessions.length; i++) {
    for (let j = 0; j < window.allSessions[i].topics.length; j++) {
      if (window.DOWNLOAD_TOPICS.indexOf(window.allSessions[i].topics[j].UUID) >= 0) {
        activeSessions.push(window.allSessions[i].UUID)
        break;
      }
    }
  }

  const sessions = document.querySelectorAll('.calendar-columns .session')
  for (let i = 0; i < sessions.length; i++) {
    if (activeSessions.indexOf(sessions[i].dataset.id) >= 0) {
      sessions[i].classList.add(ADDED_TO_CALENDAR_CLASS)
    } else {
      sessions[i].classList.remove(ADDED_TO_CALENDAR_CLASS)
    }
  }
}

function updateTopicActiveClass() {
  const topics = document.querySelectorAll('.topics-wrapper .topic')
  for (let i = 0; i < topics.length; i++) {
    if (window.DOWNLOAD_TOPICS.indexOf(topics[i].dataset.id) >= 0) {
      topics[i].classList.add(ADDED_TO_CALENDAR_CLASS)
    } else {
      topics[i].classList.remove(ADDED_TO_CALENDAR_CLASS)
    }
  }
}
