/**
 * A callback to be performed once a delegated event matches the provided conditions
 * @callback eventCallback
 * @param {object} node The node that the event triggered on
 */

/**
 * Register an event listener on a container. This uses "event-delegation" on the container. If the child
 * matches the provided one (default "a"), then the callback parameter is passed the target element of the event
 * @param {string} delegateTo The CSS selector for the delegate
 * @param {eventCallback} callback
 * @param {string} [selector=a] The item to look for
 * @param {string} [eventType=click] The event to register
 */
export default function delegateEvent(
  delegateTo,
  callback,
  selector = 'a',
  eventType = 'click'
) {
  document.querySelector(delegateTo).addEventListener(eventType, event => {
    event.preventDefault()

    // if it matches the selector
    if (event.target.matches(selector)) {
      callback(event.target)
    }
  })
}
