import formatDate from "./formatDate";

export default function addTopicsToDocument(topics, reset = false) {
  const container = document.querySelector('.topics-wrapper .columns')
  let newContent = reset ? '' : container.innerHTML
  for (let i = 0; i < topics.length; i++) {
    newContent += require('!!ejs-compiled-loader?{}!../../_partials/capture-the-event/singleTopic.ejs')(
      {topic: topics[i], date: formatDate(topics[i].start)}
    )
  }

  container.innerHTML = newContent

  // also toggle the "load more" button
  const loadMoreParent = document.querySelector('.topics-wrapper .load-more').parentNode
  loadMoreParent.style.visibility = window.FILTERED_TOPICS.length ? 'visible' : 'hidden'
  loadMoreParent.style.pointerEvents = window.FILTERED_TOPICS.length ? 'initial' : 'none'
}
