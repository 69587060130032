import toggleTopicForDownload from "../utils/toggleTopicForDownload";

export default function (topic) {
  const wrapper = document.createElement('div')
  wrapper.classList.add('topic')

  const figure = document.createElement('figure')
  const img = document.createElement('img')
  img.src = `/images/topics/${topic.img || 'default.png'}`
  if (!topic.img) {
    img.classList.add(topic.type)
    img.style.minWidth = '200px'
  }

  const caption = document.createElement('figcaption')
  caption.innerHTML = topic.name
  figure.appendChild(img)
  figure.appendChild(caption)

  wrapper.appendChild(figure)

  const contentWrapper = document.createElement('div')
  contentWrapper.classList.add('topic-content')

  const title = document.createElement('div')
  title.classList.add('title')
  title.innerHTML = topic.name

  const summary = document.createElement('div')
  summary.classList.add('summary')
  summary.innerHTML = topic.shortDescription

  const button = document.createElement('a')
  button.classList.add('add-to-calendar')

  const buttonInner = document.createElement('span')
  buttonInner.innerHTML = addToCalendarButtonText(topic.UUID)

  button.appendChild(buttonInner)
  button.addEventListener('click', event => {
    event.preventDefault()
    toggleTopicForDownload(topic)
    event.target.querySelector('span').innerHTML = addToCalendarButtonText(topic.UUID)
  })

  contentWrapper.appendChild(title)
  contentWrapper.appendChild(summary)
  contentWrapper.appendChild(button)
  wrapper.appendChild(contentWrapper)

  return wrapper
}

function addToCalendarButtonText(UUID) {
  return isTopicSelectedForDownload(UUID) ? `Remove from calendar` : `Add to calendar`
}

function isTopicSelectedForDownload(UUID) {
  return !!window.DOWNLOAD_TOPICS.filter(ID => ID === UUID).length
}
