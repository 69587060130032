export default function createModal() {
  const body = document.querySelector('body')

  const backdrop = document.createElement('div')
  backdrop.classList.add('modal-backdrop')
  body.appendChild(backdrop)

  const modal = document.createElement('div')
  modal.classList.add('modal')

  const modalContent = document.createElement('div')
  modalContent.classList.add('modal-content')
  modal.appendChild(modalContent)

  const closeButton = document.createElement('a')
  closeButton.classList.add('close')
  closeButton.innerHTML = '&times;';
  closeButton.addEventListener('click', closeModal)
  modal.appendChild(closeButton)

  body.appendChild(modal)
  return modalContent
}

function closeModal() {
  document.querySelector('.modal-backdrop').remove()
  document.querySelector('.modal').remove()
}
