import {saveAs} from 'file-saver'
import generateICS from "./generateICS";

export default function downloadCalendar() {
  const events = getTopics()

  if (!events.length) {
    return
  }

  const content = generateICS(events)
  const blob = new Blob([content], {type: "text/calendar;charset=utf-8"});
  saveAs(blob, 'calendar.ics')
}

function getTopics() {
  const events = []

  window.allSessions.map(session => {
    session.topics.map(topic => {
      if (window.DOWNLOAD_TOPICS.indexOf(topic.UUID) >= 0) {
        events.push(topic)
      }
    })
  })

  return events
}
