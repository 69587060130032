export default function (dayLabel, dateLabel) {
  const wrapper = document.createElement('div')
  wrapper.classList.add('column-header')

  const dayLabelTag = document.createElement('span')
  dayLabelTag.classList.add('day-label')
  dayLabelTag.innerHTML = dayLabel

  const dateLabelTag = document.createElement('span')
  dateLabelTag.classList.add('date-label')
  dateLabelTag.innerHTML = dateLabel

  wrapper.appendChild(dayLabelTag)
  wrapper.appendChild(dateLabelTag)

  return wrapper
}
