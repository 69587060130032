import { v5 as uuidv5 } from 'uuid'

// a constant namespace for all UUIDs
const NAMESPACE = '33cacc73-884e-4124-8cc9-ff3c22aa999f'

/**
 * Generate the same UUID given the same seed
 * @param {string} seed
 * @return {string}
 */
export default function getUUID (seed) {
  if (!seed) {
    throw 'Parameter "seed" is not valid.'
  }
  return uuidv5(seed, NAMESPACE).toUpperCase()
}
