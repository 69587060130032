import delegateEvent from './delegateEvent'
import refreshFiltering from './refreshFiltering'
import downloadCalendar from '../calendar/utils/downloadCalendar'
import { populateTopicFilters } from './populateFilters'
import { LOAD_MORE_COUNT } from './constants'
import addTopicsToDocument from './addTopicsToDocument'
import showTopicModal from './showTopicModal'
import createModal from './createModal'

window.PREVIOUS_SESSION_FILTER = ''
window.PREVIOUS_TOPIC_FILTER = ''

export default function registerEventListeners() {
  registerLoadMoreTags()
  registerLoadMoreTopics()
  registerLoadMoreSpeakers()
  registerSessionFilters()
  registerTagFilters()
  registerMenuToggle()
  registerTabToggle()
  registerDownloadCalendar()
  registerOpenTopic()
  registerPlayVideoButton()
  registerFloorPlan()
}

/**
 * Clicking the "Load More" button on the Tags/Topics filters
 */
function registerLoadMoreTags() {
  const trigger = document.querySelector(
    '.capture-the-event .filters .load-more'
  )
  if (trigger) {
    trigger.addEventListener('click', (event) => {
      event.preventDefault()
      populateTopicFilters()
      if (!window.TOPIC_NAMES.length) {
        trigger.parentNode.remove()
      }
    })
  }
}

/**
 * Clicking the "Load More" button on the displayed topics
 */
function registerLoadMoreTopics() {
  document
    .querySelector('.topics-wrapper .load-more')
    .addEventListener('click', (event) => {
      event.preventDefault()
      let topics = window.FILTERED_TOPICS.splice(0, LOAD_MORE_COUNT)
      addTopicsToDocument(topics, false)
    })
}

/**
 * Functionality for when a Session filter is selected
 */
function registerSessionFilters() {
  delegateEvent('.session-filter .filtering-options', (item) => {
    refreshFiltering(item.dataset.filter, 'session')
  })
  delegateEvent(
    '.session-filter .filtering-options',
    (item) => {
      if (item.value !== window.PREVIOUS_SESSION_FILTER) {
        refreshFiltering(item.value, 'session')
        window.PREVIOUS_SESSION_FILTER = item.value
      }
    },
    'select',
    'change'
  )
}

/**
 * Functionality for when a Tag/Topic filter is selected
 */
function registerTagFilters() {
  delegateEvent('.topic-filter .filtering-options', (item) => {
    refreshFiltering(item.dataset.filter, 'topic')
  })
  delegateEvent(
    '.topic-filter .filtering-options',
    (item) => {
      if (item.value !== window.PREVIOUS_TOPIC_FILTER) {
        refreshFiltering(item.value, 'topic', true)
        window.PREVIOUS_TOPIC_FILTER = item.value
      }
    },
    'select',
    'change'
  )
}

function registerMenuToggle() {
  const hamburger = {
    menuToggle: document.querySelector('.menu-trigger'),
    menu: document.querySelector('.menu-section'),
    menuItem: document.querySelectorAll('.main-menu--item a'),
    menuClose: document.querySelector('.close-menu'),

    doToggle: function (e) {
      e.preventDefault()
      this.menu.classList.toggle('menu-section__open')
      document.body.classList.add('scroll-block')
    },
  }

  hamburger.menuToggle.addEventListener('click', function (e) {
    hamburger.doToggle(e)
  })
  hamburger.menuItem.forEach(function (e) {
    e.addEventListener('click', function () {
      hamburger.menu.classList.remove('menu-section__open')
      document.body.classList.remove('scroll-block')
    })
  })
  hamburger.menuClose.addEventListener('click', function () {
    hamburger.menu.classList.remove('menu-section__open')
    document.body.classList.remove('scroll-block')
  })
}

function registerTabToggle() {
  const ACTIVE_CLASS = 'active'
  delegateEvent(
    '.capture-the-event .tabs',
    (link) => {
      const target = link.dataset.target
      const container = document.querySelector('.capture-the-event')
      container
        .querySelectorAll(`.tabs a:not([data-target="${target}"])`)
        .forEach((item) => item.classList.remove(ACTIVE_CLASS))
      container
        .querySelectorAll(`.tabs-content .tab:not(.${target})`)
        .forEach((item) => item.classList.remove(ACTIVE_CLASS))
      link.classList.add(ACTIVE_CLASS)
      container
        .querySelector(`.tabs-content .tab.${target}`)
        .classList.add(ACTIVE_CLASS)
      container
        .querySelectorAll('.agenda-info')
        .forEach(
          (item) =>
            (item.style.display = target === 'calendar-view' ? 'block' : 'none')
        )
    },
    '.tab-item a'
  )
}

function registerDownloadCalendar() {
  const button = document.querySelector('.download-calendar')
  button.addEventListener('click', (event) => {
    event.preventDefault()
    window.gtag('event', 'click', {
      'event_category': 'download_calendar',
      'event_label': 'Download'
    })
    downloadCalendar()
  })
}

function registerOpenTopic() {
  delegateEvent(
    '.topics-wrapper .columns',
    (item) => {
      showTopicModal(item.dataset.id)
    },
    'a.learn-more'
  )
}

function registerLoadMoreSpeakers() {
  const button = document.querySelector('#speakers .load-more')
  button.addEventListener('click', (event) => {
    event.preventDefault()
    const speakers = document.querySelectorAll('#speakers .speaker')
    speakers.forEach((item) => item.classList.remove('hidden'))
    button.parentNode.style.display = 'none'
  })
}

function registerPlayVideoButton() {
  document
    .querySelector('.video-wrapper .play-video')
    .addEventListener('click', (event) => {
      event.preventDefault()
      event.target.parentNode.querySelector('video').play()
      window.gtag('event', 'video_play', {}) // GA tracking
      event.target.remove()
    })

  document.querySelector('video').addEventListener('play', (event) => {
    let placeholderButton = event.target.parentNode.querySelector('.play-video')
    if (placeholderButton) {
      placeholderButton.remove()
    }
  })
}

function registerFloorPlan() {
  document.querySelectorAll('.open-plan').forEach(item => {
    item.addEventListener('click', (event) => {
      event.preventDefault()
      const modal = createModal()
      const img = document.createElement('img')
      img.src = '/images/floor-plan-lobby.jpg'
      img.classList.add('floor-plane-full')
      modal.appendChild(img)
      modal.parentNode.classList.add('modal--full')
    })
  })
}
