import createModal from "../../utils/createModal";
import generateSessionTopic from "../generators/generateSessionTopic";

export default function (event) {
  event.preventDefault()
  const ID = event.target.dataset.id
  const session = window.allSessions.filter(s => s.UUID === ID)[0]

  if (!session) {
    return
  }

  const modal = createModal()
  for (let i = 0; i < session.topics.length; i++) {
    modal.appendChild(generateSessionTopic(session.topics[i]))
  }
}
