import { DAY_NAMES } from './constants'

export default function formatDate (dateString) {
  let date = new Date(Date.parse(dateString))

  let dayName = DAY_NAMES[date.getDay()]
  let day = (date.getDate() < 10 ? '0' : '') + date.getDate()
  let month = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
  let year = String(date.getFullYear()).substr(2)

  return `${dayName} ${day}.${month}.${year}`
}
