module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"topic column col-3 col-md-6 col-xs-12\" data-id=\"")
    ; __append( topic.UUID )
    ; __append("\">\n  <figure>\n    <img src=\"/images/topics/")
    ; __append( topic.img || "default.png" )
    ; __append("\" alt=\"\" class=\"")
    ; __append( topic.img ? "" : topic.type )
    ; __append("\" />\n    <figcaption>")
    ; __append( topic.name )
    ; __append("</figcaption>\n  </figure>\n<!--  <div class=\"date\">")
    ; __append( date )
    ; __append("</div>-->\n  <div class=\"description\">")
    ; __append( topic.shortDescription )
    ; __append("</div>\n  <a href=\"#\" data-id=\"")
    ; __append( topic.UUID )
    ; __append("\" class=\"learn-more\">Learn more</a>\n</div>\n")
  }
  return __output;

}