import groupSessionsByDay from "../utils/groupSessionsByDay";
import generateSingleColumn from "./generateSingleColumn";
import generateDropdownOption from "./generateDropdownOption";

export default function () {
  const groupedByDay = groupSessionsByDay()
  const container = document.querySelector(
    '.calendar-wrapper .calendar-columns'
  )

  // create the mobile dropdown
  const dropdown = document.createElement('select')
  dropdown.id = 'select-date'
  const header = document.querySelector('.calendar-wrapper .header')
  const dropdownWrapper = document.createElement('div')
  dropdownWrapper.classList.add('custom-select')
  dropdownWrapper.appendChild(dropdown)
  header.appendChild(dropdownWrapper)

  for (let i in groupedByDay) {
    if (groupedByDay.hasOwnProperty(i)) {
      container.appendChild(generateSingleColumn(groupedByDay[i]))
      dropdown.appendChild(generateDropdownOption(groupedByDay[i]))
    }
  }
}
