import pad from './pad'
import {
  EVENT_START_DATE,
  MINUTE_IN_SECONDS,
  HOUR_IN_SECONDS,
  DAY_IN_SECONDS
} from './constants'

window.COUNTDOWN_INTERVAL = null

export default function startCountdown () {
  window.COUNTDOWN_INTERVAL = setInterval(() => {
    let values = calculateCountdown()

    if (!values) {
      clearInterval(window.COUNTDOWN_INTERVAL)
      document.getElementById('register-question').remove()
      document.querySelector('.countdown-wrapper .countdown-title').remove()
      document.querySelector('.countdown-wrapper .countdown').innerHTML = '<div class="post-launch">Join the virtual event</div>'
      document.querySelectorAll('.register-now-button').forEach(item => {
        item.querySelector('span').innerText = 'Join event'
      })
      return
    }

    updateCoundown(values)
  }, 1000)
}

/**
 * Calculates the difference between the user's local time and the start of the event
 * @return ({days: number, hours: number, minutes: number, seconds: number}|false)
 */
function calculateCountdown () {
  const startDate = new Date(Date.parse(EVENT_START_DATE))
  const now = new Date()
  let diff = Math.floor((startDate - now) / 1000)

  if (diff < 0) {
    return false
  }

  let days = Math.floor(diff / DAY_IN_SECONDS)
  diff = diff % DAY_IN_SECONDS

  let hours = Math.floor(diff / HOUR_IN_SECONDS)
  diff = diff % HOUR_IN_SECONDS

  let minutes = Math.floor(diff / MINUTE_IN_SECONDS)
  let seconds = diff % MINUTE_IN_SECONDS

  return { days, hours, minutes, seconds }
}

/**
 * Update the values in the countdown
 * @param {days: number, hours: number, minutes: number, seconds: number} values
 */
function updateCoundown (values) {
  const countdown = document.querySelector('.countdown')
  countdown.querySelector('.days span').innerText = pad(values.days)
  countdown.querySelector('.hours span').innerText = pad(values.hours)
  countdown.querySelector('.minutes span').innerText = pad(values.minutes)
  countdown.querySelector('.seconds span').innerText = pad(values.seconds)
}
