export const EVENT_START_DATE = '2020-09-08T14:00:00Z'

export const MINUTE_IN_SECONDS = 60
export const HOUR_IN_SECONDS = 60 * 60
export const DAY_IN_SECONDS = 60 * 60 * 24

export const GRID_HEIGHT_SIZE = 15
export const GRID_HEIGHT_UNIT = 'px'

export const LOAD_MORE_COUNT = 4

export const ID_TOKEN_KEY = 'idToken'
export const ACCESS_TOKEN_KEY = 'accessToken'

export const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
