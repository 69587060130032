import timeSpanToDimension from "../utils/timeSpanToDimension";
import dateToSecondsOffset from "../utils/dateToSecondsOffset";
import pad from "../../utils/pad";
import showSessionModal from "../utils/showSessionModal";

export default function (session) {
  let offsetSecondsStart = dateToSecondsOffset(session.start)
  let offsetSecondsEnd = dateToSecondsOffset(session.end)
  let percentTop = ((offsetSecondsStart - window.MIN_OFFSET) * 100) / MAX_TIME_SPAN

  const box = document.createElement('div')
  box.classList.add('session')
  box.classList.add(session.type)
  box.dataset.id = session.UUID
  box.style.top = `${percentTop}%`
  box.style.height = timeSpanToDimension(offsetSecondsEnd - offsetSecondsStart)

  const title = document.createElement('div')
  title.classList.add('title')
  title.innerHTML = session.name

  const timeInterval = document.createElement('div')
  timeInterval.classList.add('interval')
  timeInterval.innerHTML = sessionTimePeriod(session)

  box.appendChild(title)
  box.appendChild(timeInterval)

  // register the click event
  box.addEventListener('click', showSessionModal)

  return box
}

function sessionTimePeriod(session) {
  const startDate = new Date(Date.parse(session.start))
  const endDate = new Date(Date.parse(session.end))

  let startHour = startDate.getHours() % 12 || 12
  let startHourSuffix = startDate.getHours() < 12 ? 'AM' : 'PM'

  let endHour = endDate.getHours() % 12 || 12
  let endHourSuffix = endDate.getHours() < 12 ? 'AM' : 'PM'

  return `${startHour}:${pad(
    startDate.getMinutes()
  )}${startHourSuffix}-${endHour}:${pad(endDate.getMinutes())}${endHourSuffix}`
}
