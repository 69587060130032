import addTopicsToDocument from './addTopicsToDocument'
import { LOAD_MORE_COUNT } from './constants'

const ACTIVE_CLASS = 'active'

/**
 * handles the filtering and the content population functionality
 * @param value
 * @param type
 * @param forceSingle
 */
export default function refreshFiltering(
  value,
  type = 'session',
  forceSingle = false
) {
  // ensure we have the filters set globally
  window.activeSessionFilter = window.activeSessionFilter || null
  window.activeTopicFilters = window.activeTopicFilters || []

  if (type === 'session') {
    toggleSessionFilter(value)
  } else {
    toggleTopicFilter(value, forceSingle)
  }

  window.FILTERED_TOPICS = []
  populateMatchingTopics()
}

/**
 * Functionality for the Session filters
 * @param value
 */
function toggleSessionFilter(value) {
  // deactivate filter or enable new one
  if (window.activeSessionFilter === value) {
    window.activeSessionFilter = null
  } else {
    window.activeSessionFilter = value
  }

  // apply the active class to the links
  const filters = document.querySelectorAll(
    '.session-filter .filtering-options [data-filter]'
  )
  for (let i = 0; i < filters.length; i++) {
    if (filters[i].dataset.filter === window.activeSessionFilter) {
      filters[i].classList.add(ACTIVE_CLASS)
    } else {
      filters[i].classList.remove(ACTIVE_CLASS)
    }
  }
  // select the value in the dropdown
  const dropdown = document.querySelector('select#session-dropdown')
  if (dropdown) {
    dropdown.value = window.activeSessionFilter || ''
  }
}

/**
 * Functionality for the Topic filters
 * @param value
 * @param forceSingle
 */
function toggleTopicFilter(value, forceSingle = false) {
  if (forceSingle) {
    window.activeTopicFilters = value ? [value] : []
  } else {
    // activate or deactivate filter
    let indexOf = window.activeTopicFilters.indexOf(value)
    if (indexOf < 0) {
      window.activeTopicFilters.push(value)
    } else {
      window.activeTopicFilters.splice(indexOf, 1)
    }
  }

  // apply the active class
  const filters = document.querySelectorAll(
    '.topic-filter .filtering-options [data-filter]'
  )
  for (let i = 0; i < filters.length; i++) {
    let isActive =
      window.activeTopicFilters.indexOf(filters[i].dataset.filter) >= 0
    if (isActive) {
      filters[i].classList.add(ACTIVE_CLASS)
    } else {
      filters[i].classList.remove(ACTIVE_CLASS)
    }
  }

  // select the value in the dropdown
  const dropdown = document.querySelector('select#topic-dropdown')
  if (dropdown) {
    dropdown.value = value || ''
  }

  showOrHideSessionFilters()
}

/**
 * Populate the content after a filtering has been performed
 */
function populateMatchingTopics() {
  // determine the sessions we're looking at
  let sessions = window.activeSessionFilter
    ? window.allSessions.filter((s) => s.label === window.activeSessionFilter)
    : window.allSessions.slice(0)

  sessions.map((session) => {
    session.topics.map((topic) => {
      // if no filter exists, we consider it matched
      if (window.activeTopicFilters.length === 0) {
        if (!isAlreadyAdded(topic)) {
          window.FILTERED_TOPICS.push(topic)
        }
      }

      // matched after the first tag
      for (let i = 0; i < topic.tags.length; i++) {
        if (window.activeTopicFilters.indexOf(topic.tags[i]) >= 0) {
          if (!isAlreadyAdded(topic)) {
            window.FILTERED_TOPICS.push(topic)
            break
          }
        }
      }
    })
  })

  // generate and inject the new content
  let injectedTopics = window.FILTERED_TOPICS.splice(0, LOAD_MORE_COUNT)
  addTopicsToDocument(injectedTopics, true)
}

/**
 * Hide or display the session filters after a topic filter has been applied
 */
function showOrHideSessionFilters() {
  const activeTagCount = window.activeTopicFilters.length
  const sessionFilters = {}

  // determine which session filters matches the selected topic tag
  window.allSessions.map((session) => {
    const tags = collectSessionTags(session)
    let shouldBeDisplayed = !activeTagCount
    for (let i = 0; i < activeTagCount; i++) {
      if (tags.indexOf(window.activeTopicFilters[i]) >= 0) {
        shouldBeDisplayed = true
      }
    }
    sessionFilters[session.label] =
      sessionFilters[session.label] || shouldBeDisplayed
  })

  // show or hide the session filters
  let totalActiveCount = 0
  for (let i in sessionFilters) {
    if (sessionFilters.hasOwnProperty(i)) {
      totalActiveCount += sessionFilters[i] ? 1 : 0
      let selector = `.session-filter .filtering-options a[data-filter="${i}"]`
      document.querySelector(selector).style.display = sessionFilters[i]
        ? 'inline-block'
        : 'none'
    }
  }

  // if a single session matches, activate it
  if (totalActiveCount === 1) {
    for (let i in sessionFilters) {
      if (
        sessionFilters.hasOwnProperty(i) &&
        sessionFilters[i] &&
        window.activeSessionFilter !== i
      ) {
        toggleSessionFilter(i)
        break
      }
    }
  }
}

/**
 * Collects all the tags from a session, across all it's topics
 * @param session
 * @returns {[]}
 */
function collectSessionTags(session) {
  const tags = []
  session.topics.map((topic) => {
    topic.tags.forEach((tag) => {
      if (tags.indexOf(tag) < 0) {
        tags.push(tag)
      }
    })
  })
  return tags
}

/**
 * Check if a selected topic filter is already selected
 * @param topic
 * @returns {boolean}
 */
function isAlreadyAdded(topic) {
  for (let i = 0; i < window.FILTERED_TOPICS.length; i++) {
    if (window.FILTERED_TOPICS[i].shortDescription === topic.shortDescription) {
      return true
    }
  }

  return false
}
