import dateToSecondsOffset from "./dateToSecondsOffset";
import {DAY_IN_SECONDS} from "../../utils/constants";

export default function () {
  for (let i = 0; i < window.allSessions.length; i++) {
    let startSeconds = dateToSecondsOffset(window.allSessions[i].start)
    let endSeconds = dateToSecondsOffset(window.allSessions[i].end)

    // we moved to the next day
    if (startSeconds > endSeconds) {
      endSeconds += DAY_IN_SECONDS
    }

    if (!window.MIN_OFFSET || window.MIN_OFFSET > startSeconds) {
      window.MIN_OFFSET = startSeconds
    }

    if (!window.MAX_OFFSET || window.MAX_OFFSET < endSeconds) {
      window.MAX_OFFSET = endSeconds
    }
  }



  return window.MAX_OFFSET - window.MIN_OFFSET
}
