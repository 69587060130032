import {DAY_NAMES} from "../../utils/constants";

/**
 * Get the name of the day of the week
 * @return {string} dateString The date string to parse
 * @return {string}
 */
export default function (dateString) {
  let date = new Date(Date.parse(dateString))
  return DAY_NAMES[date.getDay()]
}
