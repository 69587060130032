import getUUID from './utils/getUUID'
import {populateSessionFilters, populateTopicFilters} from './utils/populateFilters'
import registerEventListeners from './utils/registerEventListeners'
import registerTrackingEvents from "./utils/registerTrackingEvents";
import formatDate from './utils/formatDate'
import startCountdown from './utils/countdownFunctionality'
import refreshFiltering from "./utils/refreshFiltering";

// import scss because webpack requires it
import '../scss/main.scss'

const sessionNames = []
window.ALL_TOPIC_NAMES = [] // will keep all, unfiltered
window.TOPIC_NAMES = [] // will be used for pagination

// parse data for easier manipulation
window.allSessions = require('./data/sessions.json')
window.allSessions.map(session => {
  if (sessionNames.indexOf(session.label) < 0) {
    sessionNames.push(session.label)
  }
  session.UUID = getUUID(`${session.name}-${session.start}`)

  session.topics.map(topic => {
    topic.start = session.start
    topic.end = session.end
    topic.type = session.type
    topic.UUID = getUUID(`${session.name}-${topic.name}-${topic.start}`)

    topic.tags.map(tag => {
      if (window.TOPIC_NAMES.indexOf(tag) < 0) {
        window.ALL_TOPIC_NAMES.push(tag)
        window.TOPIC_NAMES.push(tag)
      }
    })
  })
})

window.TOPIC_NAMES.sort();

// initialize the calendar
require('./calendar/init')

// format the dates for the initial topics
let initialTopics = document.querySelectorAll('.topics-wrapper .topic .date')
for (let i = 0; i < initialTopics.length; i++) {
  initialTopics[i].innerHTML = formatDate(initialTopics[i].innerHTML)
}

// populate the sessions and topics filters
populateSessionFilters(sessionNames)
populateTopicFilters(10)
refreshFiltering()

// countdown to the event start
startCountdown()

// register all the events listener
registerEventListeners()

// register all GA tracking events
registerTrackingEvents()
