import createModal from "./createModal";

export default function showTopicModal(UUID) {
  let searchedTopic = null

  window.allSessions.map(session => {
    session.topics.map(topic => {
      if (topic.UUID === UUID) {
        searchedTopic = topic
      }
    })
  })

  if (searchedTopic) {
    showModal(searchedTopic)
  }
}

function showModal(topic) {
  const wrapper = document.createElement('div')
  wrapper.classList.add('topic')

  const figure = document.createElement('figure')
  
  const img = document.createElement('img')
  img.src = `/images/topics/${topic.img || 'default.png'}`
  if (!topic.img) {
    img.classList.add(topic.type)
    img.style.minWidth = '200px'
  }

  const caption = document.createElement('figcaption')
  caption.innerHTML = topic.name
  figure.appendChild(img)
  figure.appendChild(caption)

  wrapper.appendChild(figure)

  const contentWrapper = document.createElement('div')
  contentWrapper.classList.add('topic-content')

  const title = document.createElement('div')
  title.classList.add('title')
  title.innerHTML = topic.name

  const summary = document.createElement('div')
  summary.classList.add('summary')
  summary.innerHTML = topic.description

  contentWrapper.appendChild(title)
  contentWrapper.appendChild(summary)
  wrapper.appendChild(contentWrapper)

  const modal = createModal()
  modal.appendChild(wrapper)
}
