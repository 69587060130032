/**
 * Receives a string or a number and pads it to the desired length.
 * @param {string|number} input
 * @param {number} [length=2]
 * @param {string} [padWith='0']
 * @param {string} [direction='LEFT']
 * @return {string}
 */
export default function pad (
  input,
  length = 2,
  padWith = '0',
  direction = 'LEFT'
) {
  input = String(input)

  if (input.length >= length) {
    return input
  }

  let padStr = padWith.repeat(length, input.length)
  let result = direction === 'LEFT' ? padStr + input : input + padStr
  return direction === 'LEFT'
    ? result.substr(-length)
    : result.substr(0, length)
}
