import {MONTH_NAMES} from "../../utils/constants";

/**
 * Get the name of the month and the suffixed day of the month (eg January 1st)
 * @return {string} dateString The date string to parse
 * @return {string}
 */
export default function (dateString) {
  let date = new Date(Date.parse(dateString))
  const month = MONTH_NAMES[date.getMonth()]

  let day = date.getDate()
  let ending = String(day).substr(-1)
  let suffix = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][parseInt(ending, 10)]

  if ([11, 12, 13].indexOf(day) >= 0) {
    suffix = 'th'
  }

  return `${month} ${day}${suffix}`
}
