export function populateSessionFilters(items) {
  const container = document.querySelector('.session-filter .filtering-options')

  // create the dropdown for the mobile functionality
  const selectWrapper = createFilterSelect('session-dropdown', 'Session:')
  const select = selectWrapper.querySelector('select')

  // attach the tags and options
  items.map(item => {
    container.appendChild(createFilterTag(item))
    select.appendChild(createFilterOption(item))
  })

  container.appendChild(selectWrapper)
}

export function populateTopicFilters(limit = null) {
  const container = document.querySelector('.topic-filter .filtering-options')
  const items = window.TOPIC_NAMES.splice(0, limit || window.TOPIC_NAMES.length)
  items.map(item => {
    container.appendChild(createFilterTag(item))
  })

  // create the dropdown for the mobile functionality
  const selectWrapper = createFilterSelect('topic-dropdown', 'Topic:')
  if (selectWrapper) {
    const select = selectWrapper.querySelector('select')
    container.appendChild(selectWrapper)
    window.ALL_TOPIC_NAMES.map(item => {
      select.appendChild(createFilterOption(item))
    })
  }
}

function createFilterTag(value) {
  const tag = document.createElement('a')
  tag.href = '#'
  tag.dataset.filter = value
  tag.innerHTML = value
  return tag
}

function createFilterSelect(id, defaultOption = 'Please select') {
  const existingSelect = document.querySelector(`select#${id}`)
  if (existingSelect) {
    return null
  }

  const select = document.createElement('select')
  select.id = id

  const option = document.createElement('option')
  option.value = ''
  option.innerHTML = defaultOption
  select.appendChild(option)

  const wrapper = document.createElement('div')
  wrapper.classList.add('custom-select')
  wrapper.appendChild(select)

  return wrapper
}

function createFilterOption(value) {
  const tag = document.createElement('option')
  tag.value = value
  tag.innerHTML = value
  return tag
}
