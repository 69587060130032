import generateCalendarColumns from "./generators/generateCalendarColumns";
import getMaxTimespan from "./utils/getMaxTimespan";
import timeSpanToDimension from "./utils/timeSpanToDimension";
import showTimezoneOffset from "./utils/showTimezoneOffset";
import delegateEvent from "../utils/delegateEvent";

window.MIN_OFFSET = null
window.MAX_OFFSET = null
window.DOWNLOAD_TOPICS = []
window.MAX_TIME_SPAN = getMaxTimespan()
window.COLUMN_HEIGHT = timeSpanToDimension(window.MAX_TIME_SPAN)

showTimezoneOffset()
generateCalendarColumns()

// mark the first column as visible (for mobile functionality)
document.querySelector('.calendar-columns .column:first-child').classList.add('mobile-active')

// register the date-change dropdown functionality
delegateEvent('.calendar-wrapper', item => {
  const columns = document.querySelectorAll('.calendar-columns .column')
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].dataset.date === item.value) {
      columns[i].classList.add('mobile-active')
    } else {
      columns[i].classList.remove('mobile-active')
    }
  }
}, 'select', 'change')
