import getUUID from "../../utils/getUUID";
import formatDateForSession from "./formatDateForSession";
import nameOfTheDay from "./nameOfTheDay";

/**
 * Parses all the sessions and groups them by the day they start. The returned
 * object also contains the labels for the day and the date
 * @return {object}
 */
export default function groupSessionsByDay() {
  const sessions = JSON.parse(JSON.stringify(window.allSessions))
  const grouped = {}

  // sort all sessions by date
  sessions.sort((a, b) => {
    return new Date(Date.parse(a.start)) - new Date(Date.parse(b.start))
  })

  // group them and attach the labels
  for (let i = 0; i < sessions.length; i++) {
    let date = formatDateForSession(sessions[i].start)
    let day = nameOfTheDay(sessions[i].start)
    let uuid = getUUID(date)

    // initialize if not already set
    if (typeof grouped[uuid] === 'undefined') {
      grouped[uuid] = {day, date, sessions: []}
    }

    grouped[uuid].sessions.push(sessions[i])
  }

  return grouped
}
